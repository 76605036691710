import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import Calendar from '../images/novas/icons/calendar.svg'

import {
  inspired,
  container,
  title,
  columns,
  column,
  programationBox,
  disciplineBox,
  btnDownload,
  btnMaterial
} from './styles/AulaoLista.styles'

const InspiredSite = props => (
  <Fragment>
    <div css={inspired} style={{ background: '#683EA2' }}>
      <div css={container}>
        <div css={title}>
          <span>PROGRAMAÇÃO</span><br />
          Aulão Sábado 10 de outubro
        </div>
        <div css={columns}>
          <div css={column}>
            <div css={programationBox}>
              <h3>Programação</h3>
              <h2>SALA ENEM</h2>
              <p><span>15h50</span> - Abertura</p>
              <p><span>16h00</span> - Química</p>
              <p><span>17h00</span> - Intervalo</p>
              <p><span>17h15</span> - História</p>
              <p><span>18h15</span> - Encerramento</p>
            </div>
          </div>
          <div css={column}>
            <div css={disciplineBox}>
              <h3><img src={Calendar} alt='' />16h00</h3>
              <h2>Química</h2>
              <p>Estequiometria - uma abordagem simplificada (Nível intermediário)</p>
              <h4>
                <span>PROFESSOR</span><br />
                Franco Pontes
              </h4>
              <h4>
                <span>ESCOLA</span><br />
                --
              </h4>
              <h4>
                <span>CIDADE</span><br />
                Macapá - AP
              </h4>
              <div css={btnDownload} title='Material em breve' target='_blank' rel='noopener noreferrer'>Material em breve</div>
            </div>
          </div>
          <div css={column}>
            <div css={disciplineBox}>
              <h3><img src={Calendar} alt='' />17h15</h3>
              <h2>História</h2>
              <p>Era Vargas (Nível avançado)</p>
              <h4>
                <span>PROFESSOR</span><br />
                Guilherme Figueiredo
              </h4>
              <h4>
                <span>ESCOLA</span><br />
                --
              </h4>
              <h4>
                <span>CIDADE</span><br />
                Rio de Janeiro - RJ
              </h4>
              <div css={btnDownload} title='Material em breve' target='_blank' rel='noopener noreferrer'>Material em breve</div>
            </div>
          </div>
        </div>
        {false && <div css={columns}>
          <div css={column}>
            <div css={programationBox}>
              <h3>Programação</h3>
              <h2>SALA IME - ITA</h2>
              <p><span>15h50</span> - Abertura</p>
              <p><span>16h00</span> - Química</p>
              <p><span>17h00</span> - Intervalo</p>
              <p><span>17h15</span> - Física</p>
              <p><span>18h15</span> - Encerramento</p>
            </div>
          </div>
          <div css={column}>
            <div css={disciplineBox}>
              <h3><img src={Calendar} alt='' />16h00</h3>
              <h2>Química</h2>
              <p>Bioquímica (Nível intermediário)</p>
              <h4>
                <span>PROFESSOR</span><br />
                Jefferson
              </h4>
              <h4>
                <span>ESCOLA</span><br />
                Objetivo
              </h4>
              <h4>
                <span>CIDADE</span><br />
                Teresina - PI
              </h4>
              <div css={btnDownload} title='Material em breve' target='_blank' rel='noopener noreferrer'>Material em breve</div>
            </div>
          </div>
          <div css={column}>
            <div css={disciplineBox}>
              <h3><img src={Calendar} alt='' />17h15</h3>
              <h2>Física</h2>
              <p>Questões de Hidrostática (Nível intermediário)</p>
              <h4>
                <span>PROFESSOR</span><br />
                Wagih Rassi
              </h4>
              <h4>
                <span>ESCOLA</span><br />
                Seriös
              </h4>
              <h4>
                <span>CIDADE</span><br />
                Brasília - DF
              </h4>
              <div css={btnDownload} title='Material em breve' target='_blank' rel='noopener noreferrer'>Material em breve</div>
            </div>
          </div>
        </div>}
        <div style={{ marginTop: 40, justifyContent: 'center', display: 'flex' }}>
          <Link
            to='/material-auloes'
            css={btnMaterial}
          >
            Aulões anteriores
          </Link>
        </div>
      </div>
    </div>
  </Fragment>
)

export default InspiredSite
